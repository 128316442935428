import React, { useState, useEffect} from 'react';
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Fade,
  Form,
  FormGroup,
  Input,
  Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
} from 'reactstrap';
import axios from './axios-config';
import swal from 'sweetalert';
import Moment from 'moment';


const Pagination = () => {
  const [modalInfo, setModalInfo] = useState([]);
  const [miVariable, setMiVariable] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow]= useState(false);
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(async() => {
let datos=[];
var stateLiq;
const result = await axios.get(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/bitacora/`+window.rectab.ID, {
  headers: {
    'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
  },
})
  .then(json => {
    Moment.locale('L');

  if(json.status===200){

    //console.log(json.data);

    let bitacora = json.data;
  //  //console.log(bitacora.tRecLogUsuario.length);
    for (var i = 0; i < bitacora.tRecLogUsuario.length; i++) {
      if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==1) {
        stateLiq = 'Observación ingreso de reclamo';
    }else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==2) {
      stateLiq="Observación ingreso de liquidacion local";
    }else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==3) {
      stateLiq="Observación ingreso de liquidacion dólares";
    }else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==4) {
      stateLiq="Anulación de reclamo";
    }else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==5) {
      stateLiq="Ingreso de reclamo";
    }else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==6) {
      stateLiq="Rechazo total del reclamo";
    }else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==7) {
      stateLiq="Rechazo parcial del reclamo";
    }  else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==8) {
        stateLiq="Archivo de reclamo";
      }
      else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==9) {
        stateLiq="Solicitud de Documentos pendientes";
      }
      else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==10) {
        stateLiq="Recordatorio de documentos pendientes";
      }
      else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==11) {
        stateLiq="Envío de reconsideración de reclamo";
      }
      else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==12) {
        stateLiq="Seguimiento interno del reclamo";
      }
      else if (bitacora.tRecLogUsuario[i].trecLogUsuarioPK.idTipoLog==13) {
        stateLiq="Regresar estado";

    }else{
      stateLiq=" N/A";
    }
    datos.push({FECHA_INGRESO: Moment(bitacora.tRecLogUsuario[i].fechaLog).format('L'), TIPO_LOG:stateLiq, LOG:bitacora.tRecLogUsuario[i].log});
    }
    window.datosbitacora = datos;

  }
  else{
  swal("Error", "No se encontro información, comunicate con tu asesor  !", "success");
  }
  })
  //eslint-disabled-next-line
  setMiVariable(window.datosbitacora);
  }, []);
const columns =[
{dataField: "FECHA_INGRESO", text:"Fecha de ingreso"},
      {dataField: "TIPO_LOG", text:"Pasos del Proceso"},
      {dataField: "LOG", text:"Comentarios"},

];

  return (
    <BootStrapTable style={{maxWidth: '1600px', width: '95%'}}
      keyField="name"
      data={miVariable}
      columns = {columns}
      pagination={paginationFactory()}
      striped
      bordered = {true}
          wrapperClasses="table-responsive"
      />

  );
};
export default Pagination;
