import React, { Component } from "react";
import loader from "./pageLoader.gif";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import $ from "jquery";
import swal from "sweetalert";
import Select from "react-select";
import axios from "./axios-config";
import Moment from "moment";
import Dropzone from "react-dropzone";
import Drop from "./dropzone.js";

class parte2 extends Component {
  constructor() {
    super();

    this.state = {
      datosEnfe: [],
      aseguradora: "",
      tipopol: "",
      numeropol: "",
      poliza: "",
      idaseguradora: "",
      fechaOcurrencia: "",
      enfermedad: "",
      comentarios: "",
      contacto: "",
      mail: "",
      telefono: "",
      idPoliza: "",
      certificado: "",
      selectedOption: null,
      selectedOptionDep: null,
      selectedOptionPol: null,
      selectedOptionCert: null,
      idCertificado: "",
      idDependiente: "",
      datosdependiente: [],
      datospoliza: [],
      datoscertificado: [],
      cliente: "",
      myFiles: [],
      setMyFiles: [],
      files: [],
      ckdep: "",
      cktit: true,
      ckgm: true,
      ckcps: true,
      ckcpn: "",
      ckvd: "",
      dep: "",
      complement: "",
      otros: "",
      reclamo: "",
      id_pais: 1,
      operacion: 9,
      valida: false,
      tiporeclamogm: "",
      vall: $("#val_creacion").val(),
      miVariableP: window.miVariable,
      selectedFile: null,
      tiporeclamovd: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.agregar_spinner_regional = this.agregar_spinner_regional.bind(this);
    this.handleChangeDep = this.handleChangeDep.bind(this);
    this.handleChangePol = this.handleChangePol.bind(this); //
    this.handleChangeAutorizacion = this.handleChangeAutorizacion.bind(this);
    this.handleChangePol = this.handleChangePol.bind(this);
    this.handleInputFecha = this.handleInputFecha.bind(this);
    this.handleInputOtros = this.handleInputOtros.bind(this);
    this.handleInputChangeConsu = this.handleInputChangeConsu.bind(this);
    this.handleInputChangeTipo = this.handleInputChangeTipo.bind(this);
    this.handleInputComentarios = this.handleInputComentarios.bind(this);
    this.handleInputContacto = this.handleInputContacto.bind(this);
    this.handleInputMail = this.handleInputMail.bind(this);
    this.handleInputTelefono = this.handleInputTelefono.bind(this);
    this.handleInputChangeComplement =
      this.handleInputChangeComplement.bind(this);
  }

  handleInputFecha(event) {
    //console.log(event.target.value);
    this.setState({ fechaOcurrencia: event.target.value });
  }
  handleInputComentarios(event) {
    //console.log(event.target.value);
    this.setState({ comentarios: event.target.value });
  }
  handleInputContacto(event) {
    this.setState({ contacto: event.target.value });
  }
  handleInputMail(event) {
    this.setState({ mail: event.target.value });
  }
  handleInputTelefono(event) {
    this.setState({ telefono: event.target.value });
  }
  handleInputOtros(event) {
    this.setState({ otros: event.target.value });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    //console.log(`Option selected:`, selectedOption);
    if (selectedOption.value == 460) {
      $("#otros").show();
    }
  };

  handleChangeDep = (selectedOptionDep) => {
    this.setState({ selectedOptionDep });
    //console.log(`Option selected:`, selectedOptionDep);
  };

  handleChangeCert = (selectedOptionCert) => {
    //console.log(selectedOptionCert);

    this.setState({ selectedOptionCert });
  };

  handleChangeAutorizacion = (selectedOptionAuto) => {
    //console.log(selectedOptionAuto);
    this.setState({ selectedOptionAuto });
  };

  handleChangePol = (selectedOptionPol) => {
    $("#ce").val("");
    //console.log("::::" + this.state.vall);
    this.setState({ selectedOptionPol });
    var index = this.state.datoscertificado.indexOf(1);
    this.state.datoscertificado.splice(index, 1);
    let mivaribleDep = "";
    if (this.state.miVariableP.tPolCertificado.length != undefined) {
      for (var i = 0; i < this.state.miVariableP.tPolCertificado.length; i++) {
        if (
          this.state.miVariableP.tPolCertificado[i].idPoliza.idPoliza ===
          selectedOptionPol.value
        ) {
          this.state.datoscertificado.push({
            value: this.state.miVariableP.tPolCertificado[i].idCertificado,
            label: this.state.miVariableP.tPolCertificado[i].numeroCertificado,
          });
          //console.log($("#ce").val());
          this.setState({
            idCertificado:
              this.state.miVariableP.tPolCertificado[i].idCertificado,
          });
          this.setState({
            certificado:
              this.state.miVariableP.tPolCertificado[i].idCertificado,
          });
          this.setState({
            selectedOptionCert:
              this.state.miVariableP.tPolCertificado[i].idCertificado,
          });
          this.handleChangeCert(
            this.state.miVariableP.tPolCertificado[i].idCertificado
          );
          $("#ce").val(this.state.miVariableP.tPolCertificado[i].idCertificado);
          this.setState({
            idPoliza:
              this.state.miVariableP.tPolCertificado[i].idPoliza.idPoliza,
          });
          this.setState({
            idCertificado:
              this.state.miVariableP.tPolCertificado[i].idCertificado,
          });
          this.setState({
            idaseguradora:
              this.state.miVariableP.tPolCertificado[i].idPoliza.idAseguradora
                .idAseguradora,
          });
          this.setState({
            tipopol:
              this.state.miVariableP.tPolCertificado[i].idPoliza.idTipoPoliza
                .codigoTipoPoliza,
          });
          this.setState({
            numeropol:
              this.state.miVariableP.tPolCertificado[i].idPoliza.numeroPoliza,
          });
          let dependientes = "";
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/dependientes/` +
                this.state.miVariableP.tPolCertificado[i].idCertificado,
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
                },
              }
            )
            .then((json) => {
              if (json.status == 200) {
                this.setState({
                  dep: json.data,
                });

                dependientes = this.state.dep;

                if (dependientes == null) {
                  //console.log("no tiene dependientes");
                } else {
                  if (dependientes.tPolDependiente.length === undefined) {
                    this.state.datosdependiente = [
                      {
                        value: dependientes.tPolDependiente.idDependiente,
                        label:
                          dependientes.tPolDependiente.primerNombre +
                          " " +
                          dependientes.tPolDependiente.primerApellido,
                      },
                    ];
                  } else {
                    for (
                      var i = 0;
                      i < dependientes.tPolDependiente.length;
                      i++
                    ) {
                      this.state.datosdependiente.push({
                        value: dependientes.tPolDependiente[i].idDependiente,
                        label:
                          dependientes.tPolDependiente[i].primerNombre +
                          " " +
                          dependientes.tPolDependiente[i].primerApellido,
                      });
                    }
                    //    mivaribleDep = this.state.datosdependiente;
                  }
                }
              }
            });
        }
      }
    } else {
      if (
        this.state.miVariableP.tPolCertificado.idPoliza.idPoliza ===
        selectedOptionPol.value
      ) {
        this.state.datoscertificado.push({
          value: this.state.miVariableP.tPolCertificado.idCertificado,
          label: this.state.miVariableP.tPolCertificado.numeroCertificado,
        });
        this.setState({ showHideDemo1: true });
        window.variableP = this.state.miVariableP.tPolCertificado;
        this.setState({
          idPoliza: this.state.miVariableP.tPolCertificado.idPoliza.idPoliza,
        });
        this.setState({
          idCertificado: this.state.miVariableP.tPolCertificado.idCertificado,
        });
        this.setState({
          idaseguradora:
            this.state.miVariableP.tPolCertificado.idPoliza.idAseguradora
              .idAseguradora,
        });
        this.setState({
          tipopol:
            this.state.miVariableP.tPolCertificado.idPoliza.idTipoPoliza
              .codigoTipoPoliza,
        });
        this.setState({
          numeropol:
            this.state.miVariableP.tPolCertificado.idPoliza.numeroPoliza,
        });
      }
      let dependientes = "";
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/dependientes/` +
            this.state.miVariableP.tPolCertificado.idCertificado,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
            },
          }
        )
        .then((json) => {
          if (json.status == 200) {
            this.setState({
              dep: json.data,
            });
            dependientes = this.state.dep;
            if (dependientes == null) {
              //console.log("no tiene dependientes");
            } else {
              for (var i = 0; i < dependientes.tPolDependiente.length; i++) {
                this.state.datosdependiente.push({
                  value: dependientes.tPolDependiente[i].idDependiente,
                  label:
                    dependientes.tPolDependiente[i].primerNombre +
                    " " +
                    dependientes.tPolDependiente[i].primerApellido,
                });
              }
            }
          }
        });
    }
  };

  componentDidMount() {
    //console.log("entro aca");
    var dat_poliza = 0;
    let miVariableP = window.miVariable;
    //console.log(miVariableP);
    if (miVariableP) {
      if (miVariableP.tPolCertificado.length != undefined) {
        //console.log("Longitud de miVariableP.tPolCertificado: ",miVariableP.tPolCertificado.length);
        for (var i = 0; i < miVariableP.tPolCertificado.length; i++) {
          //console.log("en el for ",i);
          dat_poliza =
            this.state.miVariableP.tPolCertificado[i].idPoliza.idPoliza;
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/polizas_migracion/` +
                this.state.miVariableP.tPolCertificado[i].idPoliza.idPoliza,
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
                },
              }
            )
            .then((json) => {
              if (json.status == 200) {
                
                for (
                  var a = 0;
                  a < this.state.miVariableP.tPolCertificado.length;
                  a++
                ) {                  
                  if (
                    json.data.estado != "C" &&
                    json.data.idepol ==
                      this.state.miVariableP.tPolCertificado[a].idPoliza
                        .idPoliza
                  ) {
                    this.state.datospoliza.push({
                      value:
                        this.state.miVariableP.tPolCertificado[a].idPoliza
                          .idPoliza,
                      label:
                        this.state.miVariableP.tPolCertificado[a].idPoliza
                          .idAseguradora.abreviatura +
                        "-" +
                        this.state.miVariableP.tPolCertificado[a].idPoliza
                          .idTipoPoliza.codigoTipoPoliza +
                        "-" +
                        this.state.miVariableP.tPolCertificado[a].idPoliza
                          .numeroPoliza,
                    });
                  } else {
                  }
                }
              }
            });
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/cliente/` +
                this.state.miVariableP.tPolCertificado[i].idCertificado,
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
                },
              }
            )
            .then((json) => {
              if (json.status == 200) {
                this.setState({
                  cliente:
                    json.data.primerNombre +
                    " " +
                    json.data.segundoNombre +
                    " " +
                    json.data.primerApellido +
                    " " +
                    json.data.segundoApellido,
                });
              }
            });
        }
      } else {
        ////console.log('ingrese al ese de parte2');
        this.state.datospoliza.push({
          value: this.state.miVariableP.tPolCertificado.idPoliza.idPoliza,
          label:
            this.state.miVariableP.tPolCertificado.idPoliza.idAseguradora
              .abreviatura +
            "-" +
            this.state.miVariableP.tPolCertificado.idPoliza.idTipoPoliza
              .codigoTipoPoliza +
            "-" +
            this.state.miVariableP.tPolCertificado.idPoliza.numeroPoliza,
        });

        this.state.poliza =
          this.state.miVariableP.tPolCertificado.idPoliza.idAseguradora
            .abreviatura +
          "-" +
          this.state.miVariableP.tPolCertificado.idPoliza.idTipoPoliza
            .codigoTipoPoliza +
          "-" +
          this.state.miVariableP.tPolCertificado.idPoliza.numeroPoliza;
        this.state.idPoliza =
          this.state.miVariableP.tPolCertificado.idPoliza.idPoliza;
        ////console.log('::::::::::::::::::::::::::'+this.state.miVariableP.tPolCertificado.idPoliza.idPoliza);

        this.state.idCertificado =
          this.state.miVariableP.tPolCertificado.idCertificado;
        this.state.numeroCertificado =
          this.state.miVariableP.tPolCertificado.numeroCertificado;
        ////console.log(this.state.miVariableP.tPolCertificado);
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/cliente/` +
              this.state.miVariableP.tPolCertificado.idCertificado,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
              },
            }
          )
          .then((json) => {
            if (json.status == 200) {
              this.setState({
                cliente:
                  json.data.primerNombre +
                  " " +
                  json.data.segundoNombre +
                  " " +
                  json.data.primerApellido +
                  " " +
                  json.data.segundoApellido,
              });
              window.clienteTitular = this.state.cliente;
            }
          });
      }
    }

    $("#div_dep").hide();
    if (this.state.vall == 1) {
      $("#dato_autorizacion").show();
      $("#autorizacion_de").show();
      $("#reclamo_de").hide();
      $("#dato_reclamo").hide();
      $("#dato_reclamo1").hide();
    } else {
      $("#dato_reclamo").show();
      $("#dato_reclamo1").show();
      $("#reclamo_de").show();
      $("#dato_autorizacion").hide();
      $("#autorizacion_de").hide();
    }
    let enfermedades = "";
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/enfermedades/`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then((json) => {
        if (json.status == 200) {
          this.setState({
            informacion: json.data,
          });
          enfermedades = this.state.informacion.tGmEnfermedad;

          for (var i = 0; i < enfermedades.length; i++) {
            this.state.datosEnfe.push({
              value: enfermedades[i].idEnfermedad,
              label: enfermedades[i].nombre,
            });
          }
        }
      });
  }

  handleInputChangeTipo(event) {
    //console.log(event);
    //  alert(event.target.name);
    const targett = event.target;
    const valuet =
      targett.type === "checkbox" ? targett.checked : targett.value;
    const namet = targett.name;

    if (namet === "ckgm") {
      //console.log(valuet);
      $("#ckgm").prop("checked", true);
      $("#ckvd").prop("checked", false);
      this.state.ckvd = false;
      this.setState({
        [namet]: valuet,
      });
    } else {
      $("#ckgm").prop("checked", false);
      $("#ckvid").prop("checked", true);
      this.state.ckgm = false;
      this.setState({
        [namet]: valuet,
      });
    }
  }

  handleInputChangeComplement(event) {
    //console.log(event);
    //  alert(event.target.name);
    const targett = event.target;
    const valuet =
      targett.type === "checkbox" ? targett.checked : targett.value;
    const namet = targett.name;
    if (namet === "ckcps") {
      //console.log(valuet);
      $("#ckcps").prop("checked", true);
      $("#ckcpn").prop("checked", false);
      this.state.ckcpn = false;
      this.setState({
        [namet]: valuet,
      });
    } else {
      $("#ckcps").prop("checked", false);
      $("#ckcpn").prop("checked", true);
      this.state.ckcps = false;
      this.setState({
        [namet]: valuet,
      });
    }
  }

  agregar_spinner_regional = (id_objeto) => {
    //console.log("llegue" + id_objeto);

    $("#" + id_objeto).addClass("overlay");
    $("#" + id_objeto).show();
    $("#" + id_objeto).css("display", "block");
  };

  limpiar_spinner = (id_objeto) => {
    //console.log("ingrese a limpieza");
    $("#" + id_objeto).removeClass("overlay");
    $("#" + id_objeto).html("");
    $("#" + id_objeto).hide();
  };

  enviar_digitalizacion = () => {
    //console.log(window.datoArchivo);

    //this.state.files = $('#dato').val();
    this.setState({
      files: window.datoArchivo,
    });
    //console.log(this.state.files);
    //console.log(this.state.files.length);
    //console.log(this.state.files[0].length);
    //console.log(this.state.files[0]);
    //custom-file-input
    const formData = new FormData();

    // Update the formData object autorizacion
    formData.append("codigo_aseguradora", this.state.idaseguradora);
    formData.append("codigo_tipo_poliza", this.state.tipopol);
    formData.append("numero_poliza", this.state.numeropol);
    formData.append("id_reclamo", this.state.reclamo);
    formData.append("rec_id_pais", this.state.id_pais);
    formData.append("operacion", this.state.operacion);
    if (this.state.vall == 0) {
      formData.append("autorizacion", "0");
    } else {
      formData.append("autorizacion", "9");
    }

    for (var i = 0; i < this.state.files[0].length; i++) {
      //console.log(i);
      //console.log(this.state.files[i]);
      //console.log(this.state.files[0][i].name);
      formData.append(
        "archivos[]",
        this.state.files[0][i],
        this.state.files[0][i].name
      );
    }

    var data = formData;
    //console.log(data);
    var url = `${process.env.REACT_APP_API_URL}/Gestion_Reclamo`;
    $.ajax({
      type: "POST",
      url: url,
      enctype: "multipart/form-data",
      processData: false,
      contentType: false,
      cache: false,
      data: data,
      success: function (digita) {
        var res = eval(digita);
        if (res[0] === "false") {
          swal(
            {
              title: "Somit Regional",
              text: "No fue posible digitalizar, su sesión ha finalizado, vuelva a ingresar por favor.",
              type: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#7c211e",
              confirmButtonClass: "btn-danger",
              confirmButtonText: "aceptar",
              closeOnConfirm: true,
              closeOnCancel: false,
            },
            function (isConfirm) {}
          );
        } else {
          var result = "";
          for (var i = 0; i < res.length; i++) {
            result = result + res[i] + "\n";
          }
          //                  swal('Somit Regional', result, 'warning');
        }
      },
      complete: function () {},
      error: function (digita) {},
    });

    return false;
  };

  onClear = () => {
    this.setState({
      telefono: "",
      contacto: "",
      mail: "",
      fechaOcurrencia: "",
      comentarios: "",
      selectedOption: "",
      selectedOptionDep: "",
      ckgm: "",
      ckcp: "",
      ckvd: "",
      cktit: "",
      ckcps: "",
      ckcpn: "",
      ckdep: "",
      files: [],
      archivos: [],
      datosdependiente: [],
      datospoliza: [],
      datoscertificado: [],
    });
  };

  onValidate = () => {
    var archivo = window.datoArchivo;
    if (this.state.telefono === "") {
      this.state.valida = false;
    } else if (this.state.contacto === "") {
      this.state.valida = false;
    } else if (this.state.mail === "") {
      this.state.valida = false;
    } else if (this.state.comentarios === "") {
      this.state.valida = false;
    } else if (this.state.cktit === "" && this.state.ckdep === "") {
      this.state.valida = false;
    } else if (archivo[0].length === 0) {
      this.state.valida = false;
      swal(
        "Atencion",
        "Para dar tramite a tu reclamo es necesario que adjuntes la documentación del reclamo",
        "warning"
      );
    } else {
      this.state.valida = true;
    }

    return this.state.valida;
  };

  handleInputChangeConsu(event) {
    //console.log(event);
    //  alert(event.target.name);
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    //console.log(name);

    if (name === "cktit") {
      //console.log(value);
      $("#div_dep").hide();
      $("#ckdep").prop("checked", false);
      $("#cktit").prop("checked", true);

      this.state.ckdep = false;
      this.setState({
        [name]: value,
      });
    } else {
      $("#div_dep").show(); //('disabled');
      $("#ckdep").prop("checked", false);
      $("#cktit").prop("checked", true);
      $("#dep").prop("disabled", false);
      this.state.cktit = false;
      this.setState({
        [name]: value,
      });
    }
  }

  render() {
    ////console.log(files);
    ////console.log(files.key);
    let tiporec = "";
    let comp = "";
    let enfermedad = "";
    let reclamode = "";
    let dependiente = "";
    let obs_adicional = "";

    const alerta = () => {
      $("#generar").prop("disabled", true);
      swal(
        "Tu gestión esta siendo generada, por favor espera un momento, en lo que se esta creando tu solicitud",
        {
          buttons: false,
          timer: 9000,
        }
      );

      if (this.state.cktit === true && this.state.ckdep === false) {
        reclamode = 2;
        dependiente = 0;
      } else if (this.state.cktit === false && this.state.ckdep === true) {
        reclamode = 2;
        dependiente = this.state.selectedOptionDep.value;
      } else {
        reclamode = 2;
        dependiente = 0;
      }

      //console.log("es_reclamo" + this.state.vall);
      if (this.state.vall === 1) {
        //console.log("ingrese a else auto");
        tiporec = this.state.selectedOptionAuto;
      } else {
        //console.log(this.state.cgkm);
        if (this.state.ckgm === true) {
          tiporec = 1;
        } else {
          tiporec = 2;
        }
      }

      if (this.state.ckcps === true) {
        comp = 1;
      } else {
        comp = 2;
      }

      if (this.state.otros.length != 0) {
        obs_adicional = "Diagnostico: " + this.state.otros;
      } else {
        obs_adicional = "";
      }

      if (this.state.selectedOption != null) {
        enfermedad = this.state.selectedOption.value;
      } else {
        enfermedad = 1;
      }
      if (this.state.otros.length != 0) {
        obs_adicional =
          "Diagnostico: " + this.state.otros + ", " + this.state.comentarios;
      } else {
        obs_adicional = this.state.comentarios;
      }

      var archivos = window.datoArchivo;
      let tamano = 0;
      for (var i = 0; i < archivos[0].length; i++) {
        // //console.log(archivos[0][i].size);
        tamano = tamano + archivos[0][i].size;
      }
      ////console.log(tamano);
      if (tamano >= 5242880) {
        swal(
          "Atencion",
          "No puedes exceder del limite de carga de 5 MB en tus archivos",
          "warning"
        );
        $("#generar").prop("disabled", false);
      } else {
        ////console.log(datos);
        ////console.log(this.validate());
        let vali = this.onValidate();
        //console.log(vali);
        if (vali === true) {
          let datos = [
            {
              poliza: this.state.idPoliza,
              certificado: this.state.idCertificado,
              reclamode: reclamode,
              dependiente: dependiente,
              tiporec: tiporec,
              fecha: Moment(
                this.state.fechaOcurrencia
                  ? this.state.fechaOcurrencia
                  : new Date()
              ).format("L"),
              enfermedad: enfermedad,
              comentarios: obs_adicional,
              contacto: this.state.contacto,
              mail: this.state.mail,
              telefono: this.state.telefono,
              sucursal: 1,
              usuario: 1,
              archivos: window.datoArchivo,
              comp: comp,
            },
          ];

          if (this.state.vall == 0) {
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/new/`,
                datos,
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                      "jwtToken"
                    )}`,
                  },
                }
              )
              .then(
                (response) => {
                  if (response.status == 200) {
                    swal(
                      "Exitoso",
                      "Tu solicitud ha sido creada existosamente. Seguimiento #: " +
                        response.data,
                      "success"
                    );
                    $("#generar").prop("disabled", false);
                    this.state.reclamo = response.data;
                    this.enviar_digitalizacion();
                    this.onClear();
                    //console.log(response);
                  } else {
                    swal(
                      "No ingresado",
                      "Tu solicitud no se ha podido crear, por favor intenta de nuevo",
                      "warning"
                    );
                    $("#generar").prop("disabled", false);
                  }
                },
                (error) => {
                  //console.log(error);
                  swal(
                    "No ingresado",
                    "Tu solicitud no se ha podido crear, por favor intenta de nuevo" +
                      error,
                    "error"
                  );
                  $("#generar").prop("disabled", false);
                }
              );
          } else {
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/newAutorizacion/`,
                datos,
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                      "jwtToken"
                    )}`,
                  },
                }
              )
              .then(
                (response) => {
                  if (response.status == 200) {
                    setTimeout(
                      this.limpiar_spinner("dv_gmrec_blockgeneral"),
                      4000
                    );
                    swal(
                      "Exitoso",
                      "Tu solicitud ha sido creada existosamente. Seguimiento #: " +
                        response.data,
                      "success"
                    );
                    $("#generar").prop("disabled", false);
                    this.state.reclamo = response.data;
                    this.enviar_digitalizacion();
                    this.onClear();
                    //console.log(response);
                  }
                },
                (error) => {
                  //console.log(error);
                }
              );
          }
        } else {
          swal(
            "Importante",
            "Debes completar los datos para que se genere el reclamo",
            "warning"
          );
          $("#generar").prop("disabled", false);
        }
      }
    };
    let miVariable = window.miVariable;
    ////console.log(miVariable);

    const options = this.state.datosEnfe;
    const dependientes = this.state.datosdependiente;

    const pol = this.state.datospoliza;
    ////console.log(pol);
    const tipo_autorizacion = [
      { value: "1", label: "Medicamento" },
      { value: "2", label: "Hospitalizacion" },
      { value: "3", label: "Examen" },
    ];

    const cert = this.state.datoscertificado;
    const { selectedOption } = this.state;
    const { selectedOptionDep } = this.state;
    const { selectedOptionPol } = this.state;
    const { selectedOptionAuto } = this.state;
    const { selectedOptionCert } = this.state;
    let cliente;
    ////console.log(selectedOption);
    ////console.log(selectedOptionPol);
    ////console.log('ingrse a este cliente parte2');
    cliente = this.state.cliente;

    return (
      <div id="formulario" name="formulario">
        <Card>
          <CardHeader style={{ background: "#872434", color: "white" }}>
            <strong> Formulario de ingreso </strong>
          </CardHeader>
          <CardBody>
            <Form className="was-validated">
              <row>
                <FormGroup row>
                  <Col md="1">
                    <strong>
                      <Label>Poliza:</Label>
                    </strong>
                  </Col>
                  <Col md="3">
                    <Select
                      id="pol"
                      name="pol"
                      options={pol}
                      value={selectedOptionPol}
                      onChange={this.handleChangePol}
                    />
                  </Col>
                  <Col md="1">
                    <strong>
                      <Label>Certificado:</Label>
                    </strong>
                  </Col>
                  <Col md="2">
                    <Select
                      id="ce"
                      name="ce"
                      selectedValue={cert}
                      options={cert}
                      value={cert}
                      defaultValue={cert}
                    />
                  </Col>
                  <Col md="1">
                    <strong>
                      <Label>Titular:</Label>
                    </strong>
                  </Col>
                  <Col md="4">
                    <Input
                      type="text"
                      id="disabled-input"
                      name="disabled-input"
                      value={cliente.replace(undefined, "")}
                      placeholder="Titular"
                      disabled
                    />
                  </Col>
                </FormGroup>
              </row>

              <FormGroup
                row
                id="autorizacion_de"
                name="autorizacion_de"
                display="none"
              >
                <Col md="2">
                  <strong>
                    <Label>Autorización de:</Label>
                  </strong>
                </Col>
                <Col md="2">
                  <FormGroup check className="radio">
                    <input
                      name="cktit"
                      id="cktit"
                      type="checkbox"
                      checked={this.state.titu}
                      onChange={this.handleInputChangeConsu}
                    />
                    &nbsp;&nbsp;
                    <Label check className="form-check-label" htmlFor="radio1">
                      Titular
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup check className="radio">
                    <input
                      name="ckdep"
                      id="ckdep"
                      type="checkbox"
                      checked={this.state.depe}
                      onChange={this.handleInputChangeConsu}
                    />
                    &nbsp;&nbsp;
                    <Label check className="form-check-label" htmlFor="radio2">
                      Dependiente
                    </Label>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup row id="reclamo_de" name="reclamo_de">
                <Col md="2">
                  <strong>
                    <Label>Reclamo de:</Label>
                  </strong>
                </Col>
                <Col md="2">
                  <FormGroup check className="radio">
                    <input
                      name="cktit"
                      id="cktit"
                      type="checkbox"
                      checked={this.state.cktit}
                      onChange={this.handleInputChangeConsu}
                    />
                    &nbsp;&nbsp;
                    <Label check className="form-check-label" htmlFor="radio1">
                      Titular
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup check className="radio">
                    <input
                      name="ckdep"
                      id="ckdep"
                      type="checkbox"
                      checked={this.state.ckdep}
                      onChange={this.handleInputChangeConsu}
                    />
                    &nbsp;&nbsp;
                    <Label check className="form-check-label" htmlFor="radio2">
                      Dependiente
                    </Label>
                  </FormGroup>
                </Col>
              </FormGroup>

              <FormGroup
                row
                id="dato_autorizacion"
                name="dato_autorizacion"
                display="none"
              >
                <Col md="2">
                  <strong>
                    <Label>Tipo de Autorizacion:</Label>
                  </strong>
                </Col>
                <Col md="2">
                  <Select
                    id="pol"
                    name="pol"
                    options={tipo_autorizacion}
                    value={selectedOptionAuto}
                    onChange={this.handleChangeAutorizacion}
                  />
                </Col>
              </FormGroup>

              <FormGroup row id="dato_reclamo" name="dato_reclamo">
                <Col md="2">
                  <strong>
                    <Label>Tipo de Reclamo:</Label>
                  </strong>
                </Col>
                <Col md="2">
                  <FormGroup check className="radio">
                    <input
                      name="ckgm"
                      id="ckgm"
                      type="checkbox"
                      checked={this.state.ckgm}
                      onChange={this.handleInputChangeTipo}
                    />
                    &nbsp;&nbsp;
                    <Label check className="form-check-label" htmlFor="radio1">
                      Gastos Medicos
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup check className="radio">
                    <input
                      name="ckvd"
                      id="ckvd"
                      type="checkbox"
                      checked={this.state.ckvd}
                      onChange={this.handleInputChangeTipo}
                    />
                    &nbsp;&nbsp;
                    <Label check className="form-check-label" htmlFor="radio2">
                      Vida
                    </Label>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup row id="div_dep" name="div_dep">
                <Col md="3">
                  <strong>
                    <Label>Dependiente</Label>
                  </strong>
                </Col>
                <Col xs="12" md="9" id="prueba" name="prueba">
                  <Select
                    id="depend"
                    name="depend"
                    options={dependientes}
                    value={selectedOptionDep}
                    onChange={this.handleChangeDep}
                  />
                </Col>
              </FormGroup>
              <div id="dato_reclamo1" name="dato_reclamo1" display="none">
                <FormGroup row>
                  <Col md="2">
                    <strong>
                      {" "}
                      <Label htmlFor="date-input">Fecha de Ocurrencia</Label>
                    </strong>
                  </Col>
                  <Col xs="12" md="10">
                    <Input
                      className="form-control"
                      required="required"
                      type="date"
                      value={this.state.fechaOcurrencia}
                      onChange={this.handleInputFecha}
                      id="date-input"
                      name="date-input"
                      placeholder="date"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="5">
                    <strong>
                      <Label>
                        ¿Presenta reclamo de este diagnóstico por primera vez?
                      </Label>
                    </strong>
                  </Col>
                  <Col md="2">
                    <FormGroup check className="radio">
                      <input
                        name="ckcps"
                        id="ckcps"
                        type="checkbox"
                        checked={this.state.ckcps}
                        onChange={this.handleInputChangeComplement}
                      />
                      &nbsp;&nbsp;
                      <Label
                        check
                        className="form-check-label"
                        htmlFor="radio1"
                      >
                        Si
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup check className="radio">
                      <input
                        name="ckcpn"
                        id="ckcpn"
                        type="checkbox"
                        checked={this.state.ckcpn}
                        onChange={this.handleInputChangeComplement}
                      />
                      &nbsp;&nbsp;
                      <Label
                        check
                        className="form-check-label"
                        htmlFor="radio2"
                      >
                        No
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="12">
                    <strong>
                      <Label htmlFor="textarea-input">Enfermedad</Label>
                    </strong>
                  </Col>
                  <Col xl="12" md="12">
                    <Select
                      options={options}
                      value={selectedOption}
                      onChange={this.handleChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  id="otros"
                  name="otros"
                  style={{ display: "none" }}
                >
                  <Col md="12">
                    <strong>
                      <Label htmlFor="textarea-input">Otra Enfermedad</Label>
                    </strong>
                  </Col>
                  <Col xl="12" md="12">
                    <Input
                      className="form-control"
                      required="required"
                      type="text"
                      value={this.state.otros}
                      onChange={this.handleInputOtros}
                      id="date-input"
                      name="date-input"
                      placeholder="Ingrese su enfermedad"
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup row>
                <Col md="12">
                  <strong>
                    <Label htmlFor="textarea-input">Comentarios</Label>
                  </strong>
                </Col>
                <Col xl="12" md="12">
                  <Input
                    className="form-control"
                    required="required"
                    value={this.state.comentarios}
                    onChange={this.handleInputComentarios}
                    type="textarea"
                    name="textarea-input"
                    id="textarea-input"
                    rows="2"
                    placeholder="Ingrese comentarios del siniestro"
                  />
                </Col>
              </FormGroup>
              <div>
                <strong>Detalles de Contacto</strong>
              </div>
              <hr
                style={{ background: "#872434", size: 5, color: "#872434" }}
              />
              <FormGroup row>
                <Col md="2">
                  <strong>
                    <Label>Contacto:</Label>
                  </strong>
                </Col>
                <Col md="2">
                  <Input
                    className="form-control"
                    required="required"
                    value={this.state.contacto}
                    onChange={this.handleInputContacto}
                    type="text"
                    id="disabled-input"
                    name="disabled-input"
                    placeholder="Contacto"
                  />
                </Col>

                <Col md="2">
                  <strong>
                    <Label>Email:</Label>
                  </strong>
                </Col>
                <Col md="2">
                  <Input
                    className="form-control"
                    required="required"
                    value={this.state.mail}
                    onChange={this.handleInputMail}
                    type="email"
                    id="disabled-input"
                    name="disabled-input"
                    placeholder="Email"
                  />
                </Col>
                <Col md="2">
                  <strong>
                    <Label>Telefono:</Label>
                  </strong>
                </Col>
                <Col md="2">
                  <Input
                    className="form-control"
                    required="required"
                    value={this.state.telefono}
                    onChange={this.handleInputTelefono}
                    type="number"
                    id="disabled-input"
                    name="disabled-input"
                    placeholder="Telefono"
                  />
                </Col>
              </FormGroup>
              <strong>Documentos</strong>
              <hr
                style={{ background: "#872434", size: 5, color: "#872434" }}
              />

              <center>
                <h4>
                  El limite de tamaño de archivos no puede exceder los 5 MB.
                </h4>
              </center>

              <div>
                <Drop />
              </div>

              <br></br>
              <FormGroup className="form-actions">
                <Button
                  id="generar"
                  name="generar"
                  style={{ float: "right" }}
                  size="sm"
                  onClick={() => alerta()}
                  color="success"
                >
                  Generar
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default parte2;
