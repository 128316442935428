import React, { useState, useEffect, useRef } from "react";
import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import axios from "axios";
import swal from "sweetalert";

const Pagination = () => {
  const [modalInfo, setModalInfo] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [miVariable, setMiVariable] = useState(-1);
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [count, setCount] = useState(-1);
  const countRef = useRef(0);

  useEffect(async () => {
    const aMoneda = (numero, opciones) => {
      // Valores por defecto
      opciones = opciones || {};
      opciones.simbolo = opciones.simbolo || "$";
      opciones.separadorDecimal = opciones.separadorDecimal || ".";
      opciones.separadorMiles = opciones.separadorMiles || ",";
      opciones.numeroDeDecimales =
        opciones.numeroDeDecimales >= 0 ? opciones.numeroDeDecimales : 2;
      opciones.posicionSimbolo = opciones.posicionSimbolo || "i";
      const CIFRAS_MILES = 3;

      // Redondear y convertir a cadena
      var num = parseFloat(numero);
      ////console.log(num);
      let numeroComoCadena = num.toFixed(opciones.numeroDeDecimales);

      // Comenzar desde la izquierda del separador o desde el final de la cadena si no se proporciona
      let posicionDelSeparador = numeroComoCadena.indexOf(
        opciones.separadorDecimal
      );
      if (posicionDelSeparador === -1)
        posicionDelSeparador = numeroComoCadena.length;
      let formateadoSinDecimales = "",
        indice = posicionDelSeparador;
      // Ir cortando desde la derecha de 3 en 3, y concatenar en una nueva cadena
      while (indice >= 0) {
        let limiteInferior = indice - CIFRAS_MILES;
        // Agregar separador si cortamos más de 3
        formateadoSinDecimales =
          (limiteInferior > 0 ? opciones.separadorMiles : "") +
          numeroComoCadena.substring(limiteInferior, indice) +
          formateadoSinDecimales;
        indice -= CIFRAS_MILES;
      }
      let formateadoSinSimbolo = `${formateadoSinDecimales}${numeroComoCadena.substr(
        posicionDelSeparador,
        opciones.numeroDeDecimales + 1
      )}`;
      return opciones.posicionSimbolo === "i"
        ? opciones.simbolo + formateadoSinSimbolo
        : formateadoSinSimbolo + opciones.simbolo;
    };
    // Probar
    const opcionesQuetzales = {
        numeroDeDecimales: 2,
        separadorDecimal: ".",
        separadorMiles: ",",
        simbolo: "Q ", // Con un espacio, ya que la función no agrega espacios
        posicionSimbolo: "i", // i = izquierda, d = derecha
      },
      opcionesDolares = {
        numeroDeDecimales: 2,
        separadorDecimal: ".",
        separadorMiles: ",",
        simbolo: " USD", // Con un espacio, ya que la función no agrega espacios
        posicionSimbolo: "d", // i = izquierda, d = derecha
      };

    const formatterQuetzales = new Intl.NumberFormat("es-GT", {
      style: "currency",
      currency: "GTQ",
      minimumFractionDigits: 2,
    });

    ////console.log(window.rectab.ID);
    let datos = [];
    var stateLiq;
    var suma = 0;
    var num = 0;
    var n = 1;
    const result = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/liquidacion/` +
          window.rectab.ID,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
          },
        }
      )
      .then((json) => {
        if (json.status === 200) {
          window.liquidacion = json.data;
          let liquidacion = window.liquidacion;
          ////console.log(formatterQuetzales.format(25));
          for (var i = 0; i < liquidacion.tRecLiqMonto.length; i++) {
            if (liquidacion.tRecLiqMonto[i].TRecLiqMontoPK.idTipoMonto == 1) {
              stateLiq = "Monto Reclamado";
            } else if (
              liquidacion.tRecLiqMonto[i].TRecLiqMontoPK.idTipoMonto == 2
            ) {
              stateLiq = "(-) Monto no cubierto";
            } else if (
              liquidacion.tRecLiqMonto[i].TRecLiqMontoPK.idTipoMonto == 3
            ) {
              stateLiq = "(-) Monto iva";
            } else if (
              liquidacion.tRecLiqMonto[i].TRecLiqMontoPK.idTipoMonto == 4
            ) {
              stateLiq = "(-) Monto deducible";
            } else if (
              liquidacion.tRecLiqMonto[i].TRecLiqMontoPK.idTipoMonto == 5
            ) {
              stateLiq = "(-) Monto coaseguro";
            } else if (
              liquidacion.tRecLiqMonto[i].TRecLiqMontoPK.idTipoMonto == 6
            ) {
              stateLiq = "(-) Monto timbres ";
            } else if (
              liquidacion.tRecLiqMonto[i].TRecLiqMontoPK.idTipoMonto == 7
            ) {
              stateLiq = "Monto pagado";
            } else {
              stateLiq = "N/A";
            }
            // var suma=+liquidacion.tRecLiqMonto[i].monto;

            while (n <= i) {
              num = num + parseInt(liquidacion.tRecLiqMonto[i].monto);
              n = n + 1;
            }
            //console.log(num);

            datos.push({
              DESCRIPCION: stateLiq,
              MONTO: aMoneda(
                liquidacion.tRecLiqMonto[i].monto,
                opcionesQuetzales
              ),
            });
          }
          //console.log(suma);
          window.datos = datos;
          //miVariable=datos;
        } else {
          swal(
            "Error",
            "No se encontro información, comunicate con tu asesor  !",
            "success"
          );
        }
      });

    //eslint-disabled-next-line
    setMiVariable(window.datos);
  }, []);

  const columns = [
    {
      dataField: "DESCRIPCION",
      text: "DESCRIPCION",
      headerAlign: "center",
      dataAlign: "right",
      footerAlign: "right",
    },
    { dataField: "MONTO", text: "MONTO", headerAlign: "right", align: "right" },
  ];

  if (miVariable) {
    return (
      <BootStrapTable
        style={{ maxWidth: "1600px", width: "95%" }}
        keyField="DESCRIPION"
        data={miVariable}
        columns={columns}
        pagination={paginationFactory()}
        striped
        bordered={true}
        wrapperClasses="table-responsive"
      />
    );
  }
};

export default Pagination;
