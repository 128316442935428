import logo from './logo.jpg';
import './App.css';
import General from './general.js';
import {Navbar, NavbarToggler} from 'reactstrap';
import {FaUserAlt} from 'react-icons/fa';
import {FaAddressBook} from 'react-icons/fa';
import {FaMailBulk} from 'react-icons/fa';
import {FaWhatsappSquare} from 'react-icons/fa';
import {FaPhoneSquareAlt} from 'react-icons/fa';
import {FaAddressCard} from 'react-icons/fa';
import {FaMapMarker} from 'react-icons/fa';
import {FaLinkedin} from 'react-icons/fa';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaInstagramSquare} from 'react-icons/fa';
import { FaCalculator } from 'react-icons/fa';
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Row
} from 'reactstrap';
import $ from 'jquery';
import axios from 'axios';

import swal from 'sweetalert';
function App() {

  const NewContact = () =>{
    axios.post(`${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/contacto_nuevo/` + $('#email').val(),{
			headers: {
				'Authorization': `Bearer ${sessionStorage.getItem('jwtToken')}`,
			},
		}).then(json => {
          if (json.status === 200 || json.status==200) {
            ////console.log(json);
          swal("Ingreso Exitoso", "Se ha almacenado correctamente tu correo", "success");
          } else {
            swal("Error", "No se pudo guardar", "warning");

          }
        }).catch(function(error) {

          swal("Error", "No ha ingresado", "warning");

        })

  }
  return (<div >
    <div >
      <header >

        <Navbar  expand="sm">

          <img height="145px" src={logo} width="auto" alt="logo"/>

        </Navbar>

        <hr style={{
            background: '#872434',
            height: "30px",
            color: '#872434'
          }}/>
      </header>

      <body>
        <General/>
      </body>
<br></br>
  <footer style={{
      background: '#872434',
      height: "auto",
      color: '#FFFFFF'
    }}>
    <row>
      <div class="row" align="center">
        <Col xs="12" md="4" style={{padding: '30px'}}>
        <h2>Servicio al Cliente</h2>
          <hr style={{
              background: '#FFFFFF',
              size: 5,
              color: '#FFFFFF'
            }}/>
          <br></br>
          <div align="left">
            <div>

          <FaAddressBook title="Pagina" size='40px'/>&nbsp;&nbsp;<a title="Pagina" style={{
                height: "auto",
           color: '#FFFFFF'
         }} href="https://www.somit.com/" target="_blank">www.somit.com</a>

         </div>
            <div>

          <FaPhoneSquareAlt title="Telefono" size='40px'/>&nbsp;&nbsp;<a title="Telefono" style={{
                height: "auto",
           color: '#FFFFFF'
         }} href="tel:+50223868310" target="_blank">Cabina 24/7 (502)23868310</a>

         </div>
         <div>
       <FaMailBulk title="Correo" size='40px'/>&nbsp;&nbsp;<a title="Correo" style={{
             height: "auto",
        color: '#FFFFFF'
      }} href="mailto:contactenosgt@somit.com" target="_blank">contactenosgt@somit.com</a>

      </div>
         <div>
       <FaCalculator title="Cotizar" size='40px'/>&nbsp;&nbsp;<a title="Cotizar" style={{
             height: "auto",
        color: '#FFFFFF'
        }} href="https://www.somit.com/cotizadores/" target="_blank">Cotizar</a>

      </div>
         </div>
      </Col>
        <Col xs="12"  md="4" style={{padding: '30px'}}>
      <h2>Newsletter</h2>
        <hr style={{
            background: '#FFFFFF',
            size: 5,
            color: '#FFFFFF'
          }}/>
        <br></br>
        <div>
        Recibe información de nuestras últimas novedades en productos y asesoria
        </div>
          <br></br>
        <div>
        <Input id="email" name="email" type="email" placeholder="Ingresar tu correo @" size="2" bsSize="2" ></Input>
        </div>
          <br></br>
        <div>
        <Button id="email" name="email" onClick={() => NewContact()} type="submit">Suscríbete</Button>
        </div>
      </Col>
        <Col  xs="12"  md="4" style={{padding: '30px'}}>
      <h2>Nuestra Comunidad</h2>
        <hr style={{
            background: '#FFFFFF',
            size: 5,
            color: '#FFFFFF'
          }}/>
        <br></br>
      <div align='left' >
      <div  >
        <a style={{
              color: '#FFFFFF'
            }} href='https://instagram.com/somit_corredores_de_seguros'target="_blank"> <FaInstagramSquare style={{
              height: "auto",
              color: '#FFFFFF'
            }} size="40px"/>@somit_corredores_de_seguros</a>
            </div>
            <div >
              <a style={{
                    color: '#FFFFFF'
                  }} href='https://www.facebook.com/somitseguros/' target="_blank"> <FaFacebookSquare style={{
                    height: "auto",
                    color: '#FFFFFF'
                  }} size="40px"/>somit_corredores_de_seguros</a>
                  </div>
                  <div>
                    <a style={{
                          color: '#FFFFFF'
                        }} href='https://www.linkedin.com/company/somit-corredores-de-seguros/mycompany/' target="_blank"> <FaLinkedin style={{
                          height: "auto",
                          color: '#FFFFFF'
                        }} size="40px"/>somit_corredores_de_seguros</a>
                        </div>
                        <div>
                          <a style={{
                                color: '#FFFFFF'
                              }} href='https://wa.me/50222856501' target="_blank"> <FaWhatsappSquare style={{
                                height: "auto",
                                color: '#FFFFFF'
                              }} size="40px"/>(502) 22856501</a>
                              </div>
                              </div>
      </Col>

    </div>
  </row>
  <row>
<br></br>
<div class="row" align="center">
<Col xs="4" md="4">

</Col>
<Col xs="12"  md="4">
<h6><strong>Somit Corredores de Seguros S.A. 1990 - 2022</strong></h6>
</Col>
<Col  xs="4"  md="4">

</Col>

</div>
  </row>
  </footer>

    </div>
  </div>);
}

export default App;
