/*
 * This codebox demonstrates three behaviors that do not depend on each other.
 *
 * NOTE: Manual reloads are likely needed to see warnings.
 */
import React, { useState } from "react";
import { ReCaptchaProvider, ReCaptchaV3 } from "react-recaptcha-x";

/*
 * 1: A static V3 instance works as expected (recieves a string token)
 */
const SHOW_WORKING = true;
/*
 * 2: A V3 instance addition after the library is loaded will not recieve a token and will
 *  likely trigger a warning:
 *
 *  - If the library is loaded when the component is created: Does not work & Warning.
 *  - If the library is not loaded when the component is created: Works as expected.
 *
 * NOTE: A manual reload is likely needed to see the warning.
 */
const SHOW_WARNING_BEFORE_MOUNT = true;

/**
 * 3: A V3 instance removal can trigger a state-after-unmount warning
 *
 * This can be tricky to reproduce:
 *
 *  - React must render the component: React may remove it from the
 *    virtual DOM Before ever rendering itequires React to render it
 *  - An async call must be in-progress (e.g: loading the recaptcha library
 *    is loading or a token request is in progress)
 *
 * For consistency, I've toggled a remove after the token process has started,
 * but the warning can also be produced more intermitently BEFORE the the recaptcha
 * library finishes loading:
 *
 *  - If the instance is rendered and removed before the library loads: Warning
 *  - If the instance is removed too early, but React doesn't render it: No warning
 *  - If the instance is removed too late (after the library load): No warning
 *  - If the instance is near another, react may just update props: No warning
 *
 * NOTE: A manual reload is likely needed to see the warning.
 */
const SHOW_WARNING_AFTER_UNMOUNT = true;

const RECAPTCHA3_KEY = "6Le17bEeAAAAAKsC94qct1EJNJmD9Grg7YEDNH1l";

function Capcha() {
  // init -> loading -> loaded -> swapped
  const [status, setStatus] = useState(
    SHOW_WARNING_AFTER_UNMOUNT ? "init" : "loading"
  );

  const checkRecaptchaState = function() {
    if (status !== "loading") return;
    if (typeof grecaptcha === "undefined") {
      setTimeout(checkRecaptchaState, 1000);
      return;
    }
    setStatus("loaded");
  };

  if (status === "loading") {
    checkRecaptchaState();
  }

  function receiveToken(type, token) {
    //console.log(`Token ${type}:`, typeof token);
  }

  return (
    <ReCaptchaProvider
      siteKeyV3={RECAPTCHA3_KEY}
      langCode="en"
      hideV3Badge={false}
    >
      {SHOW_WARNING_AFTER_UNMOUNT && status === "init" ? (
        <ReCaptchaV3
          key="init"
          action="init"
          callback={handle => {
            receiveToken("Init", handle);
            if (typeof handle === "undefined") {
              setTimeout(() => setStatus("loading"));
            }
          }}
        />
      ) : (
        ""
      )}
      {SHOW_WORKING ? (
        <ReCaptchaV3
          key="working"
          action="working"
          callback={token => receiveToken("Working", token)}
        />
      ) : (
        ""
      )}
      {SHOW_WARNING_BEFORE_MOUNT && status === "loaded" ? (
        <ReCaptchaV3
          key="swap"
          action="swap"
          callback={token => receiveToken("Loaded", token)}
        />
      ) : (
        ""
      )}
    </ReCaptchaProvider>
  );
}

export default Capcha;
