import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import swal from 'sweetalert';
import { FaSearch } from 'react-icons/fa';
import { FaFile } from 'react-icons/fa';
import { FaCar } from 'react-icons/fa';
import { FaClinicMedical } from 'react-icons/fa';
import { FaLaptopMedical } from 'react-icons/fa';
import { FaFileInvoice } from 'react-icons/fa';
import { FaBookMedical } from 'react-icons/fa';
import { FaCalendarPlus } from 'react-icons/fa';
import {EReCaptchaV2Size, EReCaptchaV2Theme, ReCaptchaProvider, ReCaptchaV2} from 'react-recaptcha-x';
import $ from 'jquery';
import Moment from 'moment';
import Capcha from "./captcha.js";

class Forms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      informacion: [],
      poliza: "",
      crea: "",
      creaauto: "",
      consultaGM: "",
      consultaGMIndi: "",
      consultaAu: "",
      cliente: "",
      producto: "",
      tipopoliza: "",
      dpi: "",
      regexp: 999999,
      val: "",
      rec:"",
      fecha: "",
      validacion: "",
      name: "React",
      showHideDemo1: false,
      showHideDemo2: false,
      showHideDemo3: false,
      showHideDemo5: false,
      modal: false,
      large: false,
      small: false,
      primary: false,
      success: false,
      warning: false,
      danger: false,
      info: false,
      collapse: true,
      fadeIn: true,
      npoliza: '',
      timeout: 300
    };
    this.toggle1 = this.toggle1.bind(this);
    this.toggleFade1 = this.toggleFade1.bind(this);
    this._reCaptchaRef = React.createRef();
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeFecha = this.onChangeFecha.bind(this);
    this.onChangeRec = this.onChangeRec.bind(this);
    this.handleInputChangeConsu = this.handleInputChangeConsu.bind(this);
    this.getCookieDpi = this.getCookieDpi.bind(this);
    this.getCookieFecha = this.getCookieFecha.bind(this);
  }


   getCookieDpi = name => {
     var cookiestring  = document.cookie;
     var cookiearray = cookiestring.split(';');
     for(var i =0 ; i < cookiearray.length ; ++i){
         if(cookiearray[i].trim().match('^'+name+'=')){
           //console.log( cookiearray[i].replace(`${name}=`,'').trim());
                        return cookiearray[i].replace(`${name}=`,'').trim();
         }
     } return null;
  };

  getCookieFecha = name => {
    var cookiestring  = document.cookie;
    var cookiearray = cookiestring.split(';');
    for(var i =0 ; i < cookiearray.length ; ++i){
        if(cookiearray[i].trim().match('^'+name+'=')){
          //console.log( cookiearray[i].replace(`${name}=`,'').trim());
            return Moment(cookiearray[i].replace(`${name}=`,'').trim()).format('YYYY-MM-DD');
        }
    } return null;
 };

  handleInputChangeConsu(a) {
  //console.log("Opcion seleccionado: ",a);  

  if (a === 'creareclamo') {
     $('#creareclamo').css('border','3px solid #003479');
     $('#consultaautorizacion').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#creaautorizacion').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#consultareclamogm').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#consultareclamogmindi').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#val_creacion').val(0);
    window.consultar = 'showHideDemo2';
      this.setState({crea:'true'});
      this.setState({creaauto:'false'});
    this.setState({consultaGM:'false'});
    this.setState({consultaAu:'false'});
      this.setState({consultaGMIndi:'false'});
  } else if (a==='consultareclamoindi') {
    $('#consultareclamogmindi').css('border','3px solid #003479');
     $('#consultareclamogm').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#consultaautorizacion').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#creaautorizacion').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#creareclamo').css('border','1px solid rgba(0, 0, 0, .125)');
    //console.log('ingrese aca');
    //console.log(this.state.crea);
    window.consultar = 'showHideDemo5';
    swal({title: "Importante", text: "Debes ingresar tu # de gestion para hacer una consulta individual.", icon: "success", button: "OK!"});
    window.rec = this.state.rec;
    //console.log('dato1'+window.rec);
    $('#div_Rec').show();
    this.setState({consultaGMIndi:'true'});
      this.setState({consultaGM:'false'});
      this.setState({creaauto:'false'});
      this.setState({consultaAu:'false'});
      this.setState({crea:'false'});

  }
  else if (a==='consultareclamogm') {
     $('#consultareclamogm').css('border','3px solid #003479');
     $('#consultaautorizacion').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#creaautorizacion').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#creareclamo').css('border','1px solid rgba(0, 0, 0, .125)');
      $('#consultareclamogmindi').css('border','1px solid rgba(0, 0, 0, .125)');
    //console.log('ingrese aca');
    //console.log(this.state.crea);
    window.consultar = 'showHideDemo1';
    window.rec = this.state.rec;
    //console.log('dato1'+window.rec);
    $('#div_Rec').hide();
      this.setState({consultaGM:'true'});
      this.setState({consultaGMIndi:'false'});
      this.setState({creaauto:'false'});
      this.setState({consultaAu:'false'});
      this.setState({crea:'false'});

  }
  else if (a==='creaautorizacion') {
    $('#creaautorizacion').css('border','3px solid #003479');
    $('#consultaautorizacion').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#consultareclamogm').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#consultareclamoauto').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#creareclamo').css('border','1px solid rgba(0, 0, 0, .125)');
      $('#consultareclamogmindi').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#val_creacion').val(1);
    //console.log('ingrese aca');
    //console.log(this.state.crea);
    window.consultar = 'showHideDemo2';
    window.rec = this.state.rec;
    //console.log('dato1'+window.rec);
      this.setState({creaauto:'true'});
      this.setState({consultaGMIndi:'false'});
      this.setState({consultaGM:'false'});
this.setState({consultaAu:'false'});
 this.setState({crea:'false'});

  }
  else {
    $('#consultaautorizacion').css('border','3px solid #003479');
    $('#creaautorizacion').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#consultareclamogm').css('border','1px solid rgba(0, 0, 0, .125)');
     $('#creareclamo').css('border','1px solid rgba(0, 0, 0, .125)');
    //console.log('ingrese aca');
    window.consultar = 'showHideDemo4';
    swal({title: "Importante", text: "Debes ingresar tu # de autorizacion para validar que eres tu.", icon: "success", button: "OK!"});
    window.rec = this.state.rec;
    //console.log('dato1'+window.rec);
    $('#div_Rec').show();
      this.setState({creaauto:'false'});
      this.setState({consultaGMIndi:'false'});
    this.setState({consultaAu:'true'});
    this.setState({consultaGM:'false'});
     this.setState({crea:'false'});
  }
  }

  toggle1() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  toggleFade1() {
    this.setState((prevState) => {
      return {
        fadeIn: !prevState
      }
    });
  }

  onChangeName(e) {
    $("span").remove();
    $('#dpi').css('border-color', '#ced4da');
    $("#dpi").find("span").remove();
    this.setState({dpi: e.target.value});
  }

  onChangeRec(e) {
    $("span").remove();
    $('#rec').css('border-color', '#ced4da');
    $("#rec").find("span").remove();
    this.setState({rec: e.target.value});
  }

  onChangeFecha(e) {
    //console.log(e.target.value);
    $("span").remove();
    $('#fecha').css('border-color', '#ced4da');
    $("#fecha").find("span").remove();
    $('#dpi').css('border-color', '#ced4da');
    $("#dpi").find("span").remove();
    this.setState({fecha: e.target.value});
  }

  render() {

      //console.log(this.getCookieDpi('dpi'));
      this.state.dpi=this.getCookieDpi('dpi');
      //console.log(this.getCookieFecha('fechaNac'));
      this.state.fecha=this.getCookieFecha('fechaNac');
    if (this.state.consultaGMIndi=='true'){
      //console.log('true');

        $('#div_Rec').show();
    }else {
      //console.log('false');
        $('#div_Rec').hide();
    }

    var width = $('.g-recaptcha-response-2').parent().width();

    var scale;
    if (width < 302) {
      scale = width / 302;
    } else {
      scale = 1.0;
    }

    $('.g-recaptcha-response-2').css('transform', 'scale(' + scale + ')');
    $('.g-recaptcha-response-2').css('-webkit-transform', 'scale(' + scale + ')');
    $('.g-recaptcha-response-2').css('transform-origin', '0 0');
    $('.g-recaptcha-response-2').css('-webkit-transform-origin', '0 0');

    $(".next").prop('disabled', true);
    window.dpi = this.state.dpi;
    window.fecha = this.state.fecha;
    const styles = {
      color: 'black',
      backgroundColor: 'blue'
    }
    const v2Callback = (token) => {
      if (typeof token === 'string') {
        } else if (typeof token === 'boolean' && !token) {
        //console.log('token has expired, user must check the checkbox again');
      } else if (token instanceof Error) {
        //console.log('error. please check your network connection');
      }
    };

    const v3Callback = (token, refreshToken) => {
      if (typeof token === 'string') {
        //console.log('this is the token', token);
        if (typeof refreshToken === 'function') {
          //console.log('this is the refresh token function', refreshToken);
        }
      } else {
        //console.log('token retrieval in progress...');
      }
    };

    return (<div id="caso" name="caso">

      <Card >
        <CardHeader style={{
            background: '#872434',
            color: 'white'
          }}>
          <i className="fa fa-edit"></i>
          <strong>Gestiona tus reclamos y autorizaciones</strong>
        </CardHeader>
        <CardBody>

 <Capcha />
          <Form className="was-validated">
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label htmlFor="dpi">
                    <strong>DPI del Titular</strong>
                  </Label>
                  <Input type="number" className="form-control" value={this.state.dpi} onChange={this.onChangeName} placeholder="Ingresa tu DPI" name="dpi" id="dpi" required="required"/>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="fecha">
                    <strong>Fecha de Nacimiento del Titular</strong>
                  </Label>
                  <Input type="date" id="fecha"  value={this.state.fecha} onChange={this.onChangeFecha} name="date-input" placeholder="date" required="required"/>
                </FormGroup>
                <FormGroup id='div_Rec' name='div_Rec'>
                  <Label htmlFor="Reclamo">
                    <strong>Número de gestión</strong>
                  </Label>
                  <Input type="number" className="form-control" value={this.state.rec} onChange={this.onChangeRec} placeholder="Ingresa tu numero de gestión" name="rec" id="rec" required="required"/>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="fecha">
                    <strong>¿Qué deseás hacer?</strong>
                  </Label>
                  <hr></hr>
                    <center>  <FormGroup><Row>
            <Col xs="12" sm="3">
<Card  style={{cursor:'pointer'}} id='creareclamo' name='creareclamo' border="danger" onClick={() => this.handleInputChangeConsu('creareclamo')}>
<CardHeader  style={{
background: '#872434',
color: 'white'
}}>
<strong>Crear Reclamo GM</strong>
</CardHeader>
<CardBody>
<FaLaptopMedical color="gray"  size="5em" title="Crear Reclamo GM" />
</CardBody>
</Card>
</Col>
<Col xs="12" sm="3">
<Card style={{cursor:'pointer'}} id='consultareclamogm' name='consultareclamogm' border="danger" onClick={() => this.handleInputChangeConsu('consultareclamogm')}>
<CardHeader  style={{
background: '#872434',
color: 'white'
}}>
<strong>Consulta General</strong>
</CardHeader>
<CardBody>
<FaBookMedical color="gray"  size="5em" title="Consulta Reclamo GM"/>
</CardBody>
</Card>
</Col>
<Col xs="12" sm="3">
<Card style={{cursor:'pointer'}} id='consultareclamogmindi' name='consultareclamogmindi' border="danger" onClick={() => this.handleInputChangeConsu('consultareclamoindi')}>
<CardHeader  style={{
background: '#872434',
color: 'white'
}}>
<strong>Consulta Gestion</strong>
</CardHeader>
<CardBody>
<FaFileInvoice color="gray"  size="5em" title="Consulta Reclamo GM"/>
</CardBody>
</Card>
</Col>

<Col xs="12" sm="3">
<Card style={{cursor:'pointer'}} id='creaautorizacion' name='creaautorizacion' border="danger" onClick={() => this.handleInputChangeConsu('creaautorizacion')}>
<CardHeader  style={{
background: '#872434',
color: 'white'
}}>
<strong>Crear Autorizacion GM</strong>
</CardHeader>
<CardBody>
<FaClinicMedical color="gray"  size="5em" title="Consulta Reclamo Auto" />
</CardBody>
</Card>
</Col>
</Row>
</FormGroup>

          </center>

                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <br></br>

    </div>);
  }
}

export default Forms;
