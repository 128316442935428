import axios from "axios";
import { DesEncriptar } from "./Util/Encripcion";

const instance = axios.create({
  baseURL: process.env.REACT_APP_KEY_API, // URL base de tu API
});

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      const requestURL = response.config.url;

      //if (requestURL.includes("login")) {
        //////console.log("PATH ES LOGIN");
      //} else {
        //////console.log("response.data.datos:", response.data.datos);
        var desencriptado = DesEncriptar(response.data.datos);
        //console.log("Datos desencriptados:"+requestURL+"  ", desencriptado);
        //response.data.datos=desencriptado;
        response.data = JSON.parse(desencriptado);
      //}
    }
    return response;
  },
  (error) => {
    // Si hay un error en la respuesta, también puedes manejarlo aquí
    return Promise.reject(error);
  }
);

export default instance;
