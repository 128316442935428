import CryptoJS from 'crypto-js';


export function Encriptar(mensaje) {
 
  // debe ser de 32 caracteres para utilizar el algoritmo aes256
  const key = process.env.REACT_APP_KEY_API;
  
	const keyutf = CryptoJS.enc.Utf8.parse(key);	
	var output = CryptoJS.AES.encrypt(mensaje, keyutf, {
		mode : CryptoJS.mode.ECB
	});

  return output.toString();

 
}


export function DesEncriptar(mensaje) { 
  // debe ser de 32 caracteres para utilizar el algoritmo aes256
  const key = process.env.REACT_APP_KEY_API;  	
  // Decodificar los datos Base64
  const datosEncriptados = CryptoJS.enc.Base64.parse(mensaje);

  // Convertir la clave secreta a un objeto WordArray
  const clave = CryptoJS.enc.Utf8.parse(key);

  // Descifrar
  const datosDescifrados = CryptoJS.AES.decrypt({ ciphertext: datosEncriptados }, clave, {
      mode: CryptoJS.mode.ECB
  });

  // Convertir los datos descifrados a una cadena UTF-8
  const datosOriginales = datosDescifrados.toString(CryptoJS.enc.Utf8);
  
  return datosOriginales;
}
