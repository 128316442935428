import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo2 from "./logo2.jpg";
import { MultiStepForm, Step } from "react-multi-form";
import { Button, FormGroup } from "reactstrap";
import Forms from "./Forms.js";
import Parte1 from "./parte1.js";
import Parte2 from "./parte2.js";
import axios from "./axios-config";
import swal from "sweetalert";
import $ from "jquery";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/RingLoader";
import Moment from "moment";
import "moment/locale/es";
import { Modal } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa";
import loader from "./pageLoader.gif";
import Bitacora from "./tabATOnly.js";
import Carusel from "./carusel.js";
import Drop from "./dropzoneSingle.js";
import { Encriptar } from "./Util/Encripcion";

const Container = styled.div`
  max-width: 1200px;
  margin: 40px auto;
  @media (max-width: 590px) {
    width: 300px;
  }
`;

const General = () => {
  const [showModal5, setShowModal5] = useState(false);
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const [modalInfo1, setModalInfo1] = useState([]);
  const [datoAsesor, setdatoAsesor] = useState([]);
  const [files, setFiles] = useState([]);

  const enviar_contacto = () => {
    return window.open("https://wa.me/502" + datoAsesor);
  };

  const Digitalizar = (valor) => {
    var element = document.getElementById("drop");
    var estado = element.style.display;
    if (estado == "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  };

  const Generar = () => {
    //console.log(window.datoArchivoN);
    //console.log(modalInfo1.ID);
    setFiles(window.datoArchivoN);
    ////console.log(files);

    //custom-file-input
    const formData = new FormData();
    ////console.log(window.miVariable);
    // Update the formData object
    if (window.miVariable) {
      //console.log("window.miVariable.: ", window.miVariable);
      formData.append(
        "codigo_aseguradora",
        window.miVariable.tPolCertificado.idPoliza.idAseguradora.idAseguradora
      );
      formData.append(
        "codigo_tipo_poliza",
        window.miVariable.tPolCertificado.idPoliza.idTipoPoliza.codigoTipoPoliza
      );
      formData.append(
        "numero_poliza",
        window.miVariable.tPolCertificado.idPoliza.numero_poliza
      );
      formData.append("id_reclamo", modalInfo1.ID);
      formData.append("rec_id_pais", 1);
      formData.append("operacion", 9);
    }
    for (var i = 0; i < files[0].length; i++) {
      //console.log(i);
      //console.log(files[i]);
      //console.log(files[0][i].name);
      formData.append("archivos[]", files[0][i], files[0][i].name);
    }

    var data = formData;
    //console.log(data);
    var url = `${process.env.REACT_APP_API_URL}/Gestion_Reclamo`;
    $.ajax({
      type: "POST",
      url: url,
      enctype: "multipart/form-data",
      processData: false,
      contentType: false,
      cache: false,
      data: data,
      success: function (digita) {
        var res = eval(digita);
        if (res[0] === "false") {
          swal(
            {
              title: "Somit Regional",
              text: "No fue posible digitalizar, su sesión ha finalizado, vuelva a ingresar por favor.",
              type: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#7c211e",
              confirmButtonClass: "btn-danger",
              confirmButtonText: "aceptar",
              closeOnConfirm: true,
              closeOnCancel: false,
            },
            function (isConfirm) {}
          );
        } else {
          var result = "";
          for (var i = 0; i < res.length; i++) {
            result = result + res[i] + "\n";
          }
          //                  swal('Somit Regional', result, 'warning');
        }
      },
      complete: function () {
        //console.log(modalInfo1);

        swal(
          "Exitoso",
          "Se ha cargado tu información correctamente y notificado al asesor de tu reclamo",
          "success"
        );
      },
      error: function (digita) {},
    });

    return false;
  };

  let validacion = 0;
  const validar = () => {
    $("#dpi").css("border-color", "#ced4da");
    $("#dpi").find("span").remove();
    $("#fecha").css("border-color", "#ced4da");
    $("#fecha").find("span").remove();
    if ($("#dpi").val().length === 0 && $("#fecha").val().length === 0) {
      $("#dpi").css("border-color", "#C70039");
      $("#dpi").after("<span><strong>Campo Vacio</strong></span>");
      $("#fecha").css("border-color", "#C70039");
      $("#fecha").after("<span><strong>Campo Vacio</strong></span>");
      validacion = 0;
    } else if ($("#dpi").val().length === 0 && $("#fecha").val().length !== 0) {
      $("#dpi").css("border-color", "#C70039");
      $("#dpi").after("<span><strong>Campo Vacio</strong></span>");
      $("#fecha").css("border-color", "#ced4da");
      $("#fecha").find("span").remove();
      validacion = 0;
    } else if ($("#dpi").val().length !== 0 && $("#fecha").val().length === 0) {
      $("#fecha").css("border-color", "#C70039");
      $("#fecha").after("<span><strong>Campo Vacio</strong></span>");
      $("#dpi").css("border-color", "#ced4da");
      $("#dpi").find("span").remove();
      validacion = 0;
    } else {
      validacion = 1;
      $("#dpi").css("border-color", "#ced4da");
      $("#dpi").find("span").remove();
      $("#fecha").css("border-color", "#ced4da");
      $("#fecha").find("span").remove();
    }
    return validacion;
  };

  const toogleTrueFalse5 = () => {
    setShowModal5(handleShow5);
  };

  const ModalContentBit = () => {
    $("#drop").hide();
    return (
      <Modal
        size="xl"
        style={{ maxWidth: "1600px", width: "100%" }}
        show={show5}
        onHide={handleClose5}
      >
        <Modal.Header
          style={{ background: "#003479", color: "white" }}
          closeButton
        >
          <Modal.Title>Gestión No: {modalInfo1}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Button
              onClick={() => Digitalizar(modalInfo1)}
              style={{
                background: "#872434",
                color: "#fffff",
                cursor: "pointer",
              }}
            >
              Agregar Documentos Pendientes
            </Button>
            &nbsp; &nbsp;
            <Button
              onClick={() => enviar_contacto()}
              style={{ cursor: "pointer" }}
              id="cont_reclamo"
              name="cont_reclamo"
              color="success"
              rel="noopener noreferrer"
            >
              <FaWhatsapp size={25} />
              &nbsp;Asesor
            </Button>
          </div>

          <br></br>
          <div id="drop" name="drop" style={{ display: "none" }}>
            <Drop />
            <hr></hr>
            <div>
              <FormGroup className="form-actions">
                <Button
                  style={{ float: "right" }}
                  type="submit"
                  size="sm"
                  onClick={() => Generar()}
                  style={{ background: "#575a5d", color: "#fffff" }}
                >
                  Generar
                </Button>
              </FormGroup>
            </div>
            <br></br>
          </div>
          <br></br>

          <Bitacora />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };
  const hideComponentn = (name) => {
    //  this.toggle();
    //console.log(name);
    switch (name) {
      case "showHideDemo1":
        window.showHideDemo1 = true;
        window.showHideDemo2 = false;
        window.showHideDemo3 = false;

        // this.setState({ showHideDemo1: !this.state.showHideDemo1 });
        break;
      case "showHideDemo2":
        //console.log("muestra el 2");
        window.showHideDemo1 = false;
        window.showHideDemo2 = true;
        window.showHideDemo3 = false;
        //this.setState({ showHideDemo2: !this.state.showHideDemo2 });
        break;
      case "showHideDemo5":
        //console.log("muestra el 2");
        window.showHideDemo1 = false;
        window.showHideDemo2 = false;
        window.showHideDemo3 = true;
        //this.setState({ showHideDemo2: !this.state.showHideDemo2 });
        break;
    }
  };

  const agregar_spinner_regional = (id_objeto) => {
    //console.log("llegue" + id_objeto);

    $("#" + id_objeto).addClass("overlay");
    $("#" + id_objeto).show();
  };

  const limpiar_spinner = (id_objeto) => {
    //console.log("ingrese a limpieza" + id_objeto);
    $("#" + id_objeto).removeClass("overlay");
    $("#" + id_objeto).html("");
    $("#" + id_objeto).hide();
  };

  const GetInfo = async () => {
    agregar_spinner_regional("dv_gmrec_blockgeneral");

    hideComponentn(window.consultar);
    //console.log("window.consultar ",window.consultar);
    let valor = validar();
    let valorValidado = 0;
    let valorValidadoNo = 0;
    if (valor === 1) {
      swal(
        "Se estan consultando tus datos de ingreso, por favor espera un momento, al momento de cargar cambiará de pantala",
        {
          buttons: false,
          timer: 5000,
        }
      );
      let datos = {
        dpi: $("#dpi").val(),
        fechaNacimiento: $("#fecha").val(),
      };
      let datosEncriptados = await Encriptar(JSON.stringify(datos));
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json", // Si estás enviando datos en formato JSON
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/loginrec1`,
          datosEncriptados,
          axiosConfig
        )
        .then((json) => {
          if (json.status === 200) {
            window.miVariable = json.data;
            sessionStorage.setItem("jwtToken", json.data.token);
            limpiar_spinner("dv_gmrec_blockgeneral");
            $("#caso").find("span").remove();
            $("#dpi").find("span").remove();
            $("#dpi").css("border-color", "#ced4da");
            $("#dpi").find("span").remove();
            $("#fecha").css("border-color", "#ced4da");
            $("#fecha").find("span").remove();
            if (window.consultar == "showHideDemo1") {
              if (json.data.tPolCertificado.length >= 0) {
                for (var i = 0; i < json.data.tPolCertificado.length; i++) {
                  if (
                    json.data.tPolCertificado[i].TGmReclamoList != undefined
                  ) {
                    for (
                      var c = 0;
                      c < json.data.tPolCertificado[c].TGmReclamoList.length;
                      c++
                    ) {
                      //console.log(json.data.tPolCertificado[i].TGmReclamoList[c].TReclamo.idReclamo);
                      if (
                        $("#rec").val() ===
                        json.data.tPolCertificado[i].TGmReclamoList[c].TReclamo
                          .idReclamo
                      ) {
                        /*console.log(
                          "if:::" +
                            json.data.tPolCertificado[i].TGmReclamoList[c]
                              .TReclamo.idReclamo
                        );*/
                        valorValidado = 1;
                        /*console.log(
                          "encontrado" +
                            json.data.tPolCertificado[i].TGmReclamoList[c]
                              .TReclamo.idReclamo
                        );*/
                      } else {
                        /*console.log(
                          "else" +
                            json.data.tPolCertificado[i].TGmReclamoList[c]
                              .TReclamo.idReclamo
                        );*/
                        valorValidadoNo = 1;
                        /*console.log(
                          "no encontrado" +
                            json.data.tPolCertificado[i].TGmReclamoList[c]
                              .TReclamo.idReclamo
                        );*/
                      }
                    }
                  } else {
                    if (
                      json.data.tPolCertificado[i].TGmReclamoList != undefined
                    ) {
                      //console.log("ingrese a else" + i);
                      /*console.log(
                        json.data.tPolCertificado[i].TGmReclamoList.TReclamo
                          .idReclamo
                      );*/
                      if (
                        $("#rec").val() ===
                        json.data.tPolCertificado[i].TGmReclamoList.TReclamo
                          .idReclamo
                      ) {
                        valorValidado = 1;
                        /*console.log(
                          "encontrado" +
                            json.data.tPolCertificado[i].TGmReclamoList.TReclamo
                              .idReclamo
                        );*/
                      } else {
                        valorValidadoNo = 1;
                        /*console.log(
                          "no encontrado" +
                            json.data.tPolCertificado[i].TGmReclamoList.TReclamo
                              .idReclamo
                        );*/
                      }
                    }
                  }
                }
              } else {
                //console.log("else de prueba");
              }
              //console.log("valorValdiado" + valorValidado);
              if (valorValidado === 1 || window.consultar === "showHideDemo1") {
                //console.log("estoy en else");
                window.miVariable = json.data;
                setActive(active + 1);
              } else {
                setActive(active);
                swal({
                  title: "Importante",
                  text: "No puedes continuar con el proceso, el reclamo no existe.",
                  icon: "warning",
                  button: "OK!",
                });
              }
            } else if (window.consultar == "showHideDemo5") {
              //console.log($("#rec").val());
              //console.log("Pasa por aqui consulta: ");
              setModalInfo1($("#rec").val());
              window.rectab = $("#rec").val();
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/it-api-clientes2/rs/operaciones/cartera/reclamo/` +
                    $("#rec").val(),
                  {
                    headers: {
                      Authorization: `Bearer ${sessionStorage.getItem(
                        "jwtToken"
                      )}`,
                    },
                  }
                )
                .then((json) => {
                  if (json.status === 200) {
                    setdatoAsesor(json.data.contacto);
                  } else {
                    swal("Error", "Validando informacion General", "warning");
                  }
                })
                .catch(function (error) {
                  swal(
                    "Error",
                    "Debes validar tu usuario y contraseña, si no cuentas con usuario puedes crearlo en la opción Crear Usuario",
                    "warning"
                  );
                });
              toogleTrueFalse5();
            } else {
              //console.log("else");
              window.miVariable = json.data;
              swal({
                title: "Exitoso",
                text: "Tu información ha sido confirmada, puedes proceder con tu gestión.",
                icon: "success",
                button: "OK!",
              });
              limpiar_spinner("dv_gmrec_blockgeneral");
              setActive(active + 1);
            }
          } else {
            swal(
              "Atención",
              "No se encontro información, revisa tus datos ó comunicate con tu asesor !!",
              "info"
            );
            limpiar_spinner("dv_gmrec_blockgeneral");
          }
        })
        .catch(function (error) {
          setLoading(false);
          swal(
            "Atención",
            "No se encontro información, revisa tus datos ó comunicate con tu asesor !!",
            "info"
          );
          limpiar_spinner("dv_gmrec_blockgeneral");
        });
    } else {
      swal("Atención", "Debes completar los datos para continuar!!", "warning");
      limpiar_spinner("dv_gmrec_blockgeneral");
      setLoading(false);
    }
  };

  //  const { showHideDemo1, showHideDemo2, showHideDemo3 } = '';
  const [active, setActive] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  return (
    <Container>
      <input
        type="hidden"
        value=""
        id="val_creacion"
        name="val_creacion"
      ></input>

      <MultiStepForm activeStep={active}>
        <Step label="Inicio">
          <br></br>
          <h2>
            <strong
              style={{
                color: "#575a5d",
              }}
            >
              Gestiones
            </strong>
            <strong
              style={{
                color: "#003479",
              }}
            >
              Digitales
            </strong>
          </h2>

          <p>
            Con esta platafoma puedes dar tramite a tus autorizaciones y
            reclamos de Gastos Medicos, dar seguimiento y consultar desde
            cualquier dispositivo digital (Computadora, Tablet y celular).
          </p>
          <p>
            Solo debes de dar click en el boton SIGUIENTE que se encuentra en la
            parte inferior izquierda de la pantalla.
          </p>
          <p>
            <strong>IMPORTANTE:</strong>
            &nbsp; Puede adjuntar sus documentos en cualquiera de los siguientes
            formatos digitales: PDF, JPG, BMP, GIF , PNG, WORD, EXCEL
          </p>
          <p></p>
          <div align="center">
            <Carusel showThumbs={false} />
          </div>
          <hr
            style={{
              background: "#003479",
              color: "#003479",
              height: "3px",
            }}
          />
        </Step>
        <Step label="Ingresar">
          <Forms />
        </Step>
        <Step label="Acciones">
          {window.showHideDemo3 && <ModalContentBit />}
          {window.showHideDemo2 && <Parte2 />}
          {window.showHideDemo1 && <Parte1 />}
          <div
            id="dv_gmrec_blockgeneral"
            style={{
              display: "none",
            }}
          >
            <div
              style={{
                position: "tofixed",
                left: "50%",
                top: "50%",
              }}
            >
              <div>
                <div className="lockscreen-logo"></div>
                <div className="lockscreen-name"></div>
                <center>
                  <img src={loader} />
                </center>
                <div className="help-block text-center"></div>
                <div className="text-center"></div>
                <center>
                  <strong>Cargando información, espere un momento</strong>
                </center>
                <div className="lockscreen-footer text-center">
                  <strong>Copyright &copy; 2022</strong>
                  <strong>
                    <a href="http://somit.com" className="text-black">
                      Somit S.A
                    </a>
                    &nbsp; All rights reserved
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </Step>
      </MultiStepForm>
      <br></br>
      {active !== 1 && (
        <Button onClick={() => setActive(active - 1)}>Regresar</Button>
      )}
      {active !== 2 && active !== 3 && (
        <Button
          onClick={() => setActive(active + 1)}
          style={{
            float: "right",
          }}
        >
          Siguiente
        </Button>
      )}
      {active == 2 && (
        <Button
          onClick={() => GetInfo()}
          style={{
            float: "right",
          }}
        >
          Siguiente
        </Button>
      )}
    </Container>
  );
};

export default General;
