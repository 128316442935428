import * as React from "react";
import { useDropzone } from "react-dropzone";
import "core-js/modules/es.array.from";
import swal from "sweetalert";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import $ from 'jquery';

const DropZone = () => {
  $('#generar').prop('disabled', false);
  const [myFiles, setMyFiles] = React.useState([]);

  const onDrop = React.useCallback(
    acceptedFiles => {
      var contError=0;
      acceptedFiles.forEach(element => {
        var nombre=element.name.split('.');
        //console.log(element);

        var tamanoLimite = 5 * 1024 * 1024; // 5 megabytes
        if (element.size >= tamanoLimite) {
          swal("Error", "El archivo excede el tamaño permitido de 5MB", "error");
          // Puedes reiniciar el input para borrar la selección del usuario
          contError=contError+1;
      }

        if (nombre[0].length > 50) {                                                            
          //throwError("El nombre del archivo es muy largo, máximo 40 caracteres",element);
          swal("Error", "El nombre del archivo es muy largo, máximo 50 caracteres", "error");
          $('#generar').prop('disabled', false);
          contError=contError+1;
      }
      
      var regexNombre = /^[a-zA-Z0-9_ -]+\.[a-zA-Z]{1,5}$/;
      if (!regexNombre.test(element.name)) {                                        
          //throwError("El nombre del archivo es inválido, los caracteres permitidos son: _ - y espacios en blanco", element);
          swal("Error", "El nombre del archivo es inválido, los caracteres permitidos son: _ - y espacios en blanco", "error");
          $('#generar').prop('disabled', false);
          contError=contError+1;
      }
      });

      if(contError==0)
      {
        setMyFiles([...myFiles, ...acceptedFiles]);
      }
    },

    [myFiles]
  );

    window.datoArchivo=[myFiles];

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    noKeyboard: true,
    maxSize: 20971520,
    multiple: true,
    onDrop
  });

  const handleRemoveFile = React.useCallback(
    fileName => {
      const dt = new DataTransfer();
      const files = Array.from(inputRef.current.files);



      // Add selected fiels to DataTransfer object
      for (let file of files) {        
        file.name !== fileName && dt.items.add(file); // Add only file name not matched files
      }

      inputRef.current.files = dt.files; // Overwrite files
      setMyFiles(Array.from(dt.files)); // Set states to render file list
    },
    [inputRef]
  );

  const files = React.useMemo(
    () =>
      myFiles.map(file => (

        <p key={file.name}>

          <FormGroup row>
            <Col md="6">
              {file.name}
            </Col>
            <Col md="6">
          <Button color="danger"
           onClick={() => handleRemoveFile(file.name)}>Quitar</Button>
         </Col>
       </FormGroup>
        </p>
      )),
    [handleRemoveFile, myFiles]
  );

  const baseStyle = {
    flex: 2,
    border:"dotted",
    size:5,
    color:'#872434',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#872434",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    outline: "none",
    transition: "border .24s ease-in-out"
  };

  return (
    <section className="container">

      <div {...getRootProps({ className: "dropzone", style: baseStyle })}>
        <input {...getInputProps()} />
        <p>Puedes colocar aquí tus archivos o puedes buscarlos en tu computadora</p>

      </div>
      {files.length > 0 ? (
        <div>
          <h4>Lista de Archivos</h4>
           <hr style={{background:'#872434', size:2, color:'#872434'}} />
          <div>{files}</div>

        </div>
      ) : (
        ""
      )}
    </section>
  );
};

export default DropZone;
