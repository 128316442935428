import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import logoN from './logo2.jpg';
import logoS from './logo3.jpg';
import logoD from './logo4.jpg';
import logoF from './logo5.jpg';

class DemoCarousel extends Component {
    render() {
        return (
            <Carousel showThumbs={false} autoPlay={true}>
                <div>
                    <img  align="center" height="400px" src={logoN} width="auto" sizes="(min-width: 576px) 33.3vw, 10vw" alt="logo" />
                    <p className="legend">Creación de Reclamos</p>
                </div>
                <div>
                    <img  align="center" height="400px" src={logoF} width="auto" sizes="(min-width: 576px) 33.3vw, 10vw" alt="logo" />
                    <p className="legend">Creación de Autorizaciones</p>
                </div>
                <div>
                    <img  align="center" height="400px" src={logoD} width="auto" sizes="(min-width: 576px) 33.3vw, 10vw" alt="logo" />
                    <p className="legend">Consulta de Reclamos y Autorizaciones GM</p>
                </div>
            </Carousel>
        );
    }
}

export default DemoCarousel;
